<template>
  <div class="main">
    <div class="background" v-lazy:background-image="bgImg">
      <img class="background" alt="" srcset="">
    </div>
    <div class="h-100 d-flex flex-column justify-content-center align-items-center">
      <div class="content align-self-center">
        <router-view/>
      </div>
      <div class="footer">
        <small>
          <a href="https://itmr-dev.de/impressum.html" target="_blank" rel="noopener noreferrer">Impressum</a>
          <a href="https://status.itmr-dev.de/" target="_blank" rel="noopener noreferrer">System-Status</a>
        </small>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {
      bgImg: {
        src: 'bg.png',
        // error: 'http://xx.com/error.png',
        loading: 'bg-min.png',
      },
    };
  },
};
</script>

<style lang="scss">
@import "~@/assets/scss/vendors/bootstrap-vue/index";
* {
  font-family: 'Source Sans Pro', sans-serif;
  font-family: 'Poppins', sans-serif;
}

.main {
    position: relative;
    width: 100vw;
    height: 100vh;
    /*
    background:
      linear-gradient(
        rgba(0, 0, 0, 0.35),
        rgba(0, 0, 0, 0.35)
      ),
      url('./assets/bg.png');
    */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  .background {
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: -1;
    overflow: hidden;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .backgroundImage {
    max-width: 100%;
    max-height: 100%;
    bottom: 0;
    left: 0;
    margin: auto;
    overflow: auto;
    position: fixed;
    right: 0;
    top: 0;
    -o-object-fit: contain;
    object-fit: contain;
  }

  .content {
    position: relative;
    background: white;
    border-radius: 1rem;
    text-align: center;
    padding: 2rem;
    padding-top: 2.5rem;
  }

  .logoWrapper {
    left: calc(50% - 2rem);
    position: absolute;
    top: -2rem;
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    background-color: white;
  }
  .logoIcon {
    width: 2rem;
    height: 2rem;
    color: white;
  }

  .footer {
    position: absolute;
    bottom: 2rem;
    color: white;
  }
  .footer>small>a {
    color: white;
    margin-left: 0.25rem;
    margin-right: 0.25rem;
  }
</style>
