import '@babel/polyfill';
import 'mutationobserver-shim';
import Vue from 'vue';
import './plugins/bootstrap-vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import VueLazyload from 'vue-lazyload';
import App from './App.vue';
import router from './router';

Vue.use(VueLazyload);

const apiUrls = {
  local: 'http://localhost:3000/',
  dev: 'https://api-dev.itmr.dev/',
  prod: 'https://api.itmr.dev/',
};

Vue.use(VueAxios, axios);
axios.defaults.baseURL = apiUrls[process.env.VUE_APP_API_URL || 'dev'];

Vue.mixin({
  methods: {
    toast: (title, text, variant = null) => {
      this.$bvToast.toast(text, {
        title,
        variant,
        solid: true,
        autoHideDelay: 5000,
        appendToast: false.valueOf,
      });
    },
    error: (error) => {
      router.push({
        name: 'Error',
        params: {
          error,
        },
      });
    },
  },
});

Vue.config.productionTip = false;

new Vue({
  router,
  render: (h) => h(App),
}).$mount('#app');
