<template>
  <div>
    <img v-lazy="lazyLogo" class="logoWrapper" />
    <h1>Kurzlink aufrufen</h1>
    <p>Bitte gebe deinen Kurzlink Code ein.</p>
    <div class="d-flex flex-row justify-content-center">
      <b-input
        v-model="code"
        :no-wheel="true"
        type="text"
        ref="text"
        autofocus
        @update="updateCode($event)"
        trim
        placeholder="XXXXXXXX"
        style="width: 12ch; text-align: center"
      />
    </div>
    <b-button
      @click="next"
      ref="next"
      variant="primary"
      :disabled="!valid"
      style="margin-top: 1rem"
      >Aufrufen</b-button
    >
  </div>
</template>

<script>
export default {
  name: 'Home',
  data() {
    return {
      code: '',
      lazyLogo: {
        src: 'logo.png',
        loading: 'logo-min.png',
      },
    };
  },
  methods: {
    next() {
      this.$router.push({
        name: 'Redirect',
        params: {
          code: this.code,
        },
      });
    },
    updateCode(val) {
      if (val.length > 7) {
        this.next();
      }
    },
  },
  computed: {
    valid() {
      return this.code.length >= 8;
    },
  },
};
</script>

<style scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
</style>
