import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/redirect',
    name: 'Redirect',
    component: () => import(/* webpackChunkName: "Redirect" */ '../views/Redirect.vue'),
  },
  {
    path: '/qr',
    name: 'QR-Code',
    component: () => import(/* webpackChunkName: "Redirect" */ '../views/QRCode.vue'),
  },
  {
    path: '/adress',
    name: 'Adress',
    component: () => import(/* webpackChunkName: "Redirect" */ '../views/Adress.vue'),
  },
  {
    path: '/error',
    name: 'Error',
    component: () => import(/* webpackChunkName: "Error" */ '../views/Error.vue'),
  },
  {
    path: '/s/:slug',
    redirect: (to) => ({
      name: 'Redirect',
      params: { slug: to.params.slug },
    }),
  },
  {
    path: '/:code',
    redirect: (to) => ({
      name: 'Redirect',
      params: { code: to.params.code },
    }),
  },
  {
    path: '*',
    redirect: '/',
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
